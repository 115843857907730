import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import DefaultLayout from "../layouts/DefaultLayout";

interface ContentTemplateProps {
  data: {
    mdx: {
      body: string;
      frontmatter: {
        title: string;
        summary?: string;
      };
      fields: {
        url: string;
      };
    };
  };
}

const ContentTemplate: React.SFC<ContentTemplateProps> = ({ data }) => {
  const url = data.mdx.fields.url;
  const isFront = url == "/";
  const isContact = url == "/contact/";
  const isDistributorIndex = url == "/distributors/";
  const pageTitle = data.mdx.frontmatter.title;

  return (
    <DefaultLayout
      pageTitle={pageTitle}
      isFront={isFront}
      isContact={isContact}
      isDistributorIndex={isDistributorIndex}
    >
      <MDXRenderer frontmatter={data.mdx.frontmatter}>
        {data.mdx.body}
      </MDXRenderer>
    </DefaultLayout>
  );
};

export default ContentTemplate;

export const query = graphql`
  query ContentTemplateQuery($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
      }
      fields {
        url
      }
    }
  }
`;
